enum GameVersion {
    TECHNICAL = 0,
    AOK = 1,
    CONQUERORS,
    FORGOTTEN,
    AFRICAN_KINGDOMS,
    RISE_OF_RAJAS,
    DEFINITIVE_EDITION,
    LORDS_OF_THE_WEST,
    DAWN_OF_THE_DUKES,
    DYNASTIES_OF_INDIA,
    RETURN_OF_ROME,
    THE_MOUNTAIN_ROYALS,
    CHRONICLES_BATTLE_FOR_GREECE,
    THE_THREE_KINGDOMS,
}

export default GameVersion;